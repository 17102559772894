
import React from "react"
import Layout from "../components/js/layout"
import SEO from "../components/js/seo"
import queryString from "query-string"

import lecturesData from "../data/lectures.yaml"

import "../components/css/page/mobile-lecture.css"

class MobileLecture extends React.Component {

  state = {
    lecture: null,
    screenWidth: 0
  }

  insertLines = (line, key) => (
    <p key={key}>
      {line}
    </p>
  )
  insertButtons = (button, key) => (
    <a key={key} href={button.link} target="_blank" rel="noreferrer">
      {button.title}
    </a>
  )

  render () {

    return (
      <Layout page="mobile-lecture">
        <SEO title={this.state.lecture ? (this.state.lecture.title) : ''} />
        {
          this.state.lecture && (
            <section id="mobileLecture">

              <iframe 
                width={this.state.screenWidth}
                height={this.state.screenWidth * 9 / 16}
                src={this.state.lecture.youtube} 
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                allowfullscreen>
              </iframe>

              <div className="content">
                <h1>{this.state.lecture.title}</h1>
                <div>
                  {this.state.lecture.desc.map((line, key) => this.insertLines(line, key))}
                </div>
                <div className="buttons">
                  {this.state.lecture.buttons.map((button, key) => this.insertButtons(button, key))}
                </div>
              </div>
            </section>
          )
        }
      </Layout>
    )
  }

  componentDidMount () {
    const code = queryString.parse(window.location.search).code
    const lecture = lecturesData.find((lec) => {
      return lec.code === code 
    })

    if (!lecture) return

    this.setState(() => {
      return {
        lecture
      }
    })

    this.setState(() => {
      return {
        screenWidth: window.screen.width
      }
    })
  }

}

export default MobileLecture